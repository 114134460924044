import { useWindowSize } from "@uidotdev/usehooks"
import { useContext, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { ProjectsHeadlineContext } from "../../ProjectsHeadlineContext"
import { isMobile } from 'react-device-detect'


function Filter({categories, filter, handleFilter, hoveredFilter, setHoveredFilter, menuOpen, isSearch}) {

  const { showProjectHeadline } = useContext(ProjectsHeadlineContext)
  const size = useWindowSize()

  const { pathname } = useLocation()
  const splitLocation = pathname.split('/')

  const refFilterParent = useRef(null)
  const refFilter = useRef(null)
  const refFilterBtn = useRef(null)

  const [filterOffset, setFilterOffset] = useState(0)

  useEffect(() => {
    setFilterOffset((refFilterBtn.current?.parentElement.offsetWidth - refFilterBtn.current?.offsetWidth) / 2)
  }, [pathname])

  useEffect(() => {
    setFilterOffset((refFilterParent.current?.offsetWidth - refFilterBtn.current?.offsetWidth) / 2)
  }, [size.width, filterOffset]) //showProjectHeadline

  return (
    <>
      {(splitLocation[1] === 'projekte' || splitLocation[1] === 'projekt') && 
        <div ref={refFilterParent} className={`${splitLocation[1] === 'projekt' ? (showProjectHeadline ? 'visible' : 'invisible') : ''} relative md:fixed md:top-0 ${filter === null ? 'md:left-3' : 'md:left-9'} md:pt-7 md:left-auto md:z-30 w-1/2 md:w-1/3 cursor-pointer md:block ${isMobile && isSearch ? 'md:hidden' : ''}`}> {/* ${!isMobile && !menuOpen ? 'md:z-30' : ''} */}
          <div
            className={'relative md:z-40 inline-block -translate-x-6 md:-translate-x-1/2 backdrop-blur-md bg-white/70 md:bg-transparent opacity-95'}
            style={{transform: size.width > 640 ? `translateX(${filterOffset}px)` : null}}
            onMouseEnter={() => setHoveredFilter(refFilter.current)}
            onMouseLeave={() => setHoveredFilter(null)}
            onClick={() => isMobile ? setHoveredFilter(hoveredFilter ? null : refFilter.current) : null}
          >
            <div className={`relative flex flex-nowrap md:align-top w-full overflow-visible ${hoveredFilter ? 'block backdrop-blur-sm bg-transparent' : ''}`}> 
              <p ref={refFilterBtn} className={`${hoveredFilter ? 'w-full md:w-auto' : 'w-auto'} relative uppercase ml-6 md:ml-0 px-2 md:px-3 text-sm whitespace-nowrap`}> {/* border-b */}
                {filter === null ? <span className='text-sm lg:text-base font-folioMedium'>Filter</span> : filter}
              </p>

              {filter !== null &&
                <button
                  type="button"
                  className="absolute left-0 z-10 inline-flex items-center justify-center w-4 h-4 px-2 text-black right-6 md:right-auto top-1 md:-left-6 hover:text-black focus:outline-none focus:ring-0 focus:ring-inset-0"
                  onClick={() => handleFilter(null)}
                >
                  <svg className="absolute hidden w-4 h-4 md:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth=".75" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />
                  </svg>
                </button>}
            </div>

            <ul ref={refFilter} className={`${hoveredFilter ? 'block backdrop-blur-sm md:bg-transparent' : 'hidden'} absolute md:relative pt-2 ml-0 pl-8 md:pl-3 px-3 pb-4 z-50 w-full flex flex-wrap flex-col cursor-pointer`} style={{transform: `translateX(${-refFilterBtn.current?.offsetWidth})`}}>
              {categories?.map((el, i) => {
                return (
                  (el.slug !== 'archiv' && el.slug !== 'dev') &&
                    <li key={i}
                      className={`${el.slug === 'all' ? (filter === null ? 'hidden' : 'pb-2') : (el.slug === 'wettbewerbe' ? 'mb-2' : '')} ${el.name === filter ? 'underline' : ''} hover:underline underline-offset-4 whitespace-nowrap text-medium`}
                      onClick={() => handleFilter(el.slug === 'all' ? null : (filter === el.name ? null : el.name))}
                    >
                      {el.name}
                    </li>
                )
              })}
            </ul>
          </div>
        </div>}
    </>
  )

}

export default Filter